<template>
  <!-- eslint-disable  -->
  <div>
    <v-card flat class="w100p">
      <!--  -->
    </v-card>
    <div class="d-sm-flex mb-3 text-Heading6 Medium d-none">
      歷史紀錄
      <v-spacer></v-spacer>
    </div>

    <v-card
      outlined
      :color="$vuetify.breakpoint.smAndDown ? 'transparent' : null"
    >
      <DataTable
        :list="result.list"
        :total="result.total"
        :fields="headers"
        @get-list="fetchData"
        :replace="['status', 'created_at|formatTime']"
        :loading="isLoading"
      >
        <template #item.status="{ item }">
          <v-chip color="Primary050" small>代收</v-chip>
        </template>
      </DataTable>
    </v-card>
  </div>
</template>

<script>
import { getCardHistory } from '@/api/merchantCenter/cards';

export default {
  components: {},
  data() {
    return {
      isLoading: false,
      headers: [
        {
          text: '收款姓名',
          value: 'bank_holder',
          align: 'center',
          width: '120px'
        },
        {
          text: '銀行卡號',
          value: 'bank_card_no',
          align: 'center',
          width: '120px'
        },
        {
          text: '銀行名稱',
          value: 'bank_name',
          align: 'center',
          width: '120px'
        },
        {
          text: '分行名稱',
          value: 'bank_branch',
          align: 'center',
          width: '120px'
        },
        {
          text: '交易類型',
          value: 'status',
          align: 'center',
          width: '120px'
        },
        {
          text: '建立時間',
          value: 'created_at',
          align: 'center',
          width: '180px'
        }
      ],

      result: {
        list: [],
        total: 0,
        page: 1
      },
      condition: {
        page: 1,
        limit: 20
      }
    };
  },

  created() {
    this.fetchData();
  },

  methods: {
    async fetchData(condition = this.condition) {
      this.isLoading = true;
      this.condition = condition;
      const History = await getCardHistory({
        card_info: this.$route.params.bank_card_no,
        type: 'pay',
        ...condition
      });
      if (!History.error) {
        this.result.total = History.total;
        this.result.page = History.page;
        this.$set(
          this.result,
          'list',
          History.items.map((item) => ({
            ...item
          }))
        );
      }
      this.isLoading = false;
    },
    handleAction(fn) {
      fn(this);
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
